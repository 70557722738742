.privacy-policy {
    text-align: left;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #fff;
    background-color: #2b3e50;
    border-radius: 10px;
    width: 80%;
    margin: auto;
}

.privacy-policy h1,
.privacy-policy h2 {
    text-align: center;
    color: #f0db4f;
}

.privacy-policy p {
    margin: 10px 0;
}

.privacy-policy a {
    color: #61dafb;
    text-decoration: none;
}