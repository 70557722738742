.home-screen {
    text-align: center;
    font-family: Arial, sans-serif;
    color: white;
    background-color: #2e4a77;
    padding: 20px;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    width: 100px;
    height: auto;
}

.platforms {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.platform-logo {
    width: 50px;
    height: auto;
    margin: 0 10px;
}

.description {
    margin: 20px 0;
}

.how-it-works {
    text-align: left;
    max-width: 600px;
    margin: 0 auto;
}

.footer {
    margin-top: 20px;
}